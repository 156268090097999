// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// import custom css
@import '../css/app.css';

//resources/sass/app.scss
//@import '~bootstrap/scss/bootstrap';
@import '~admin-lte/dist/css/adminlte.css';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";