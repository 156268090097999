/*overlay*/
  .overlay {
      position: absolute;
  /*    top: 50%;
      left: 50%;*/
      width: 100%;
      min-height: 100%;
      z-index: 99999;
    background-color:#FFFFFF;
    opacity: 0.8;
    display: none;
  }
  .load-bar {
    position: absolute;
    margin-top: 0px;
    width: 100%;
    height: 5px;
    background-color: #ffeb3b;
    z-index: 99999;
  }
  .bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
  }
  .bar:nth-child(1) {
    background-color: #d500f9;
    animation: loading 3s linear infinite;
  }
  .bar:nth-child(2) {
    background-color: #03a9f4;
    animation: loading 3s linear 1s infinite;
  }
  .bar:nth-child(3) {
    background-color: #fdba2c;
    animation: loading 3s linear 2s infinite;
  }
  @keyframes loading {
      from {left: 50%; width: 0;z-index:100;}
      33.3333% {left: 0; width: 100%;z-index: 10;}
      to {left: 0; width: 100%;}
  }